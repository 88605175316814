<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên Khóa học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailCourse.name"
            v-model="detailCourse.name"
            @input="onKeyUp"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
          <hnr-input :text="slug" v-model="detailCourse.slug"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Mô tả khóa học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="description" rules="required" v-slot="{ errors }">
          <ck-content
            :content="detailCourse.description"
            @getData="updatedescription"
            v-model="detailCourse.description"
          ></ck-content>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center pt-5">
      <v-col cols="2" class="pa-1">
        <span> Nội dung video khóa học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="content" rules="required" v-slot="{ errors }">
          <ck-content
          :content="detailCourse.content"
          @getData="updateContent"
          v-model="detailCourse.content"
          ></ck-content>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center pt-5">
      <v-col cols="2" class="pa-1">
        <span> Lớp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="class" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailCourse.class"
            v-model="detailCourse.class"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Cấp độ khóa học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="level" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailCourse.level"
            v-model="detailCourse.level"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Tổng số video </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="total_video" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailCourse.total_video"
            v-model="detailCourse.total_video"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Giá tiền </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(detailCourse.price)"
            @input="update"
            v-model="detailCourse.price"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Giáo viên </span>
      </v-col>
      <v-col cols="10" class="pa-1 pt-10">
        <v-select
            v-model="detailCourse.teacher_id"
            :items="teacher"
            item-text="name"
            item-value="id"
            label="Chọn giáo viên"
            dense
            outlined></v-select>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tải ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1 d-flex align-center">
        <v-file-input
          class="pl-1"
          prepend-icon="mdi-camera"
          v-model="image"
          label="Tải ảnh"
          @change="onChangeFileUpload"
          @click:clear="clearImage"
        ></v-file-input>
        <v-col cols="2" class="pa-4">
          <v-img
            :src="imagePreviewUrl || replace_image"
            v-if="imagePreviewUrl || replace_image"
            contain
            class="image-container"
          ></v-img>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import mixin from "@/store/mixin";
import CkContent from "@/components/ck-content.vue";
import { SAVE_IMAGE } from "@/store/image.module";


export default {
  mixins: [mixin],
  props: {
    course: Object,
    teacher: [],
    isNew: { type: Boolean, default: false },
  },
  components: {
    CkContent,
    HnrInput
  },
  data() {
    return {
      image: {},
      show: true,
      detailCourse: {},
      imagePreviewUrl: null,
      name: "",
      slug: "",
    };
  },
  computed: {
    replace_image() {
      if (this.detailCourse.image) {
        return this.detailCourse.image;
      }
      return "";
    },
  },
  watch: {
    course: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailCourse = newVal;
      },
    },
    detailCourse: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.slug = newVal.slug;
      },
    },
  },
  methods: {
    updateContent(val) {
      this.detailCourse.content = val;
    },
    updatedescription(val) {
      this.detailCourse.description = val;
    },
    onKeyUp(value) {
      this.detailCourse.slug = this.sanitizeTitle(value);
    },
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(SAVE_IMAGE, formData).then((data) => {
        if (data.status == 1 && data.url) {
          this.detailCourse.image = data.url;
          this.imagePreviewUrl = data.url;
        }
      });
    },
    clearImage() {
      this.detailCourse.image = "";
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    },
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>
