<template>
    <v-container>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-tabs v-model="tabIndex">
          <v-tab v-for="item in items" :key="item.id">{{ item.tab }}</v-tab>
        </v-tabs>
        <v-tabs-items class="tab-content" v-model="tabIndex">
          <v-tab-item active>
            <course-content
                class="tab-detail"
                :course="detailCourse"
                :teacher="listTeachers"
                :isNew="isCreate"
            ></course-content>
          </v-tab-item>
          <v-tab-item>
            <course-seo class="tab-detail" :course="detailCourse"></course-seo>
          </v-tab-item>
        </v-tabs-items>
        <v-row v-if="isCreate" class="align-center justify-center ma-0 pt-5">
          <v-btn color="primary" @click="handleSubmit(saveSubject)">Tạo mới khóa học</v-btn>
        </v-row>
        <v-row v-else class="align-center justify-center ma-0 pt-5">
          <v-btn color="primary" @click="handleSubmit(updateSubject)">Cập nhật khóa học</v-btn>
        </v-row>
      </ValidationObserver>
    </v-container>
  </template>
  
  <script>
  import {mapGetters, mapState} from "vuex";
  import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
  import {SET_LOADING} from "@/store/loading.module";
  import {SAVE_COURSE, DETAIL_COURSE, UPDATE_COURSE} from "@/store/course.module";
  import {SUCCESS, ERROR} from "@/store/alert.module";
  import CourseContent from '../../../components/course/CourseContent.vue';
  import CourseSeo from '../../../components/course/CourseSeo.vue';
  import { GET_LIST_TEACHER } from "@/store/teacher.module";
  
  export default {
    data() {
      return {
        id: "",
        isCreate: true,
        tabIndex: 0,
        items: [ 
          {id: 0, tab: "Thông tin khóa học"},
          {id: 1, tab: "Cấu hình SEO"},
        ],
        headers: [
          {
            text: "id",
            value: "id",
          },
          {text: "Tên Chuyên mục", value: "name"},
        ],
        detailCourse: {
          id: '',
          name:"",
          slug : "",
          price:"",
          teacher_id:"",
          description:"",
          seo_title:"",
          image:"",
          content:"",
          class:"",
          level:"",
          total_video:"",
          seo_description:"",
          seo_key:"",
         
        },
      };
    },
    components: {
      CourseContent,
      CourseSeo
    },
    computed: {
      ...mapGetters({
        course: "detailCourse",
      }),
      ...mapGetters({
        listTeachers: "listTeachers",
      }),
    },
    watch: {
      course: {
        deep: true,
        immediate: true,
        handler(newVal) {
          this.detailCourse = newVal;
        },
      },
    },
    created() {
      this.id = this.$route.query.id;
    },
    mounted() {
      if (this.id) {
        this.isCreate = false;
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Sửa khóa học"}]);
  
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(DETAIL_COURSE, this.id)
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      } else {
        this.isCreate = true;
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm khóa học"}]);
      }
      this.$store.dispatch(GET_LIST_TEACHER);
    },
    methods: {
      updateSubject() {
        let payload = {
          id: this.id,
          name: this.detailCourse.name,
          slug: this.detailCourse.slug ,
          content: this.detailCourse.content ,
          description: this.detailCourse.description ,
          price: this.detailCourse.price ,
          level: this.detailCourse.level ,
          teacher_id: this.detailCourse.teacher_id ,
          image: this.detailCourse.image ,
          class: this.detailCourse.class ,
          total_video: this.detailCourse.total_video ,
          seo_key: this.detailCourse.seo_key ,
          seo_description: this.detailCourse.seo_description ,
          seo_title: this.detailCourse.seo_title,
         
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(UPDATE_COURSE, payload)
            .then((data) => {
              if (data) {
                this.$store.dispatch(SUCCESS, "Cập nhật khóa học thành công!", {
                  root: true,
                });
                this.$router.push("/course");
                this.detailCourse = {}
              } else {
                if (data.errorCode == 424) {
                  this.$store.dispatch(ERROR, data.message, {
                    root: true,
                  });
                } else {
                  this.$store.dispatch(ERROR, data.message, {
                    root: true,
                  });
                }
              }
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(ERROR, "Cập nhật khóa học thất bại!", {
                root: true,
              });
              this.$store.dispatch(SET_LOADING, false);
            });
      },
      saveSubject() {
        let payload = {
          name: this.detailCourse.name,
          slug: this.detailCourse.slug ,
          content: this.detailCourse.content ,
          description: this.detailCourse.description ,
          level: this.detailCourse.level ,
          teacher_id: this.detailCourse.teacher_id ,
          price: this.detailCourse.price ,
          image: this.detailCourse.image ,
          class: this.detailCourse.class ,
          total_video: this.detailCourse.total_video ,
          seo_key: this.detailCourse.seo_key ,
          seo_description: this.detailCourse.seo_description ,
          seo_title: this.detailCourse.seo_title
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(SAVE_COURSE, payload)
            .then((data) => {
              if (data.status) {
                this.$store.dispatch(SUCCESS, "Thêm khóa học thành công!", {
                  root: true,
                });
                this.$router.push("/course");
              } else {
                this.$store.dispatch(ERROR, data.message, {
                  root: true,
                });
              }
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(ERROR, "Thêm khóa học thất bại!", {root: true});
              this.$store.dispatch(SET_LOADING, false);
            });
      },
    },
  };
  </script>
  
  <style lang="scss">
  .tab-detail {
    margin-top: 15px;
  }
  </style>
  